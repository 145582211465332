import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UserForm from './UserForm';
import UsersTable from './UsersTable';
import axios from 'axios';
import getApiBaseUrl from './api';

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [editUserIndex, setEditUserIndex] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [username, setUsername] = useState('');

  const EMPTY_STATE = {
    userId: '',
    capacity: 0,
    keepaliveInterval: 60,
    gracePeriod: 60
  };
  const [userData, setUserData] = useState(EMPTY_STATE);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filters, setFilters] = useState({
    userId: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalUsers, setTotalUsers] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    } else {
      try {
        // JWTs are Base64URL encoded so we need to convert to Base64
        const payloadBase64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const payloadJson = atob(payloadBase64);
        const parsedJsonPayload = JSON.parse(payloadJson);
        setUsername(parsedJsonPayload.customerId);
      } catch (_) {
        setUsername('Unknown');
      }
      fetchUsers(currentPage, pageSize, filters);
    }
  }, [history, currentPage, pageSize, filters]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${getApiBaseUrl()}/logout`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      localStorage.removeItem('token');
      history.push('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const genericErrorHandler = (error, alertMessage) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Unauthorized. Please login again.');
      history.push('/login');
    }
    else {
      if (error.response && error.response.data.message) {
        alertMessage += "\n\n" + error.response.data.message;
      }
      alert(alertMessage);
    }
  }

  const fetchUsers = async (page, limit, filters = {}) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${getApiBaseUrl()}/api/v1/customer/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          skip: (page - 1) * limit,
          limit: limit,
          userId: filters.userId || '',
        }
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setPageSize(response.data.pageSize);
      setTotalUsers(response.data.totalUsers);
    } catch (error) {
      genericErrorHandler(error, 'Failed to fetch users.')
    }
  };

  const handleCancelEditUser = () => {
    setShowAddUser(false);
    setEditUserIndex(null);
    setUserData(EMPTY_STATE);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${getApiBaseUrl()}/api/v1/customer/users`, userData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      handleCancelEditUser();
      setAccessToken(response.data.accessToken);
      await fetchUsers(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to add user.')
    }
  };

  const handleEditUser = (user, index) => {
    setShowAddUser(false);
    setUserData({
      userId: user.userId,
      capacity: user.capacity,
      keepaliveInterval: user.keepaliveInterval,
      gracePeriod: user.gracePeriod
    });
    setEditUserIndex(index);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      const payload = {...userData};
      delete payload.userId;
      await axios.put(`${getApiBaseUrl()}/api/v1/customer/users/${userData.userId}`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      handleCancelEditUser();      
      await fetchUsers(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to update user.');
    }
  };

  const handleRemoveUser = async (e, userId) => {
    e.preventDefault();
    const confirmDelete = window.confirm(`Are you sure you want to delete the user with ID: '${userId}'?`);
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${getApiBaseUrl()}/api/v1/customer/users/${userId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      await fetchUsers(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove user.')
    }
  }

  const handleRemoveSelectedUsers = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(selectedUsers.map(user =>
        axios.delete(`${getApiBaseUrl()}/api/v1/customer/users/${user.userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
      ));
      setSelectedUsers([]);
      await fetchUsers(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove selected users.');
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUsers((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (sel) => sel.userId === user.userId
      );
      if (alreadySelected) {
        return prevSelected.filter(
          (sel) => sel.userId !== user.userId
        );
      } else {
        return [...prevSelected, { userId: user.userId }];
      }
    });
  };

  const handleSelectAllUsers = () => {
    const allSelected = users.every((user) =>
      selectedUsers.some((sel) => sel.userId === user.userId)
    );
    
    if (allSelected) {
      setSelectedUsers(prev => prev.filter(
        sel => !users.some(user => user.userId === sel.userId)
      ));
    } else {
      const toAdd = users
        .filter(user =>!selectedUsers.some(sel => sel.userId === user.userId))
        .map(user => ({ userId: user.userId }));

      setSelectedUsers((prev) => [...prev, ...toAdd]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [name]: value };
      return newFilters;
    });
    // reset to page 1 (common approach)
    setCurrentPage(1);
  };
  
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-secondary" onClick={handleLogout}>Logout</button>
        <h2>Welcome, {username}</h2>
        <button className={`btn btn-primary ${showAddUser ? 'invisible' : ''}`} onClick={() => {
          setEditUserIndex(null);
          setUserData(EMPTY_STATE);
          setShowAddUser(!showAddUser)
        }}>
          Add User
        </button>
      </div>

      {accessToken && (
        <div className="bg-light p-3 mb-4">
          <div className="mt-3">
            <h5 className="mb-3 text-left">New User's Access Token:</h5>
            <p style={{ fontFamily: "monospace", wordWrap: "break-word" }}>{accessToken}</p>
            <button className="btn btn-primary btn-sm" onClick={() => {
              setAccessToken('')
            }}>Close</button>
          </div>
        </div>
      )}

      {showAddUser && (
        <UserForm
          userData={userData}
          setUserData={setUserData}
          handleSubmit={handleAddUser}
          handleCancel={handleCancelEditUser}
          isEdit={false}
        />
      )}

      {editUserIndex !== null && (
        <UserForm
          userData={userData}
          setUserData={setUserData}
          handleSubmit={handleUpdateUser}
          handleCancel={handleCancelEditUser}
          isEdit={true}
        />
      )}

      <div className="mb-3">
        <h4>Filter Users</h4>
        <div className="d-flex">
          <input
            type="text"
            name="userId"
            placeholder="User ID"
            value={filters.userId}
            onChange={handleFilterChange}
            className="form-control mr-2"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button
          className="btn btn-danger"
          onClick={handleRemoveSelectedUsers}
          disabled={selectedUsers.length === 0}
        >
          Delete Selected
        </button>
        <span>{selectedUsers.length} selected</span>
      </div>

      <UsersTable
        users={users}
        handleEditUser={handleEditUser}
        handleRemoveUser={handleRemoveUser}
        selectedUsers={selectedUsers}
        handleSelectUser={handleSelectUser}
        handleSelectAllUsers={handleSelectAllUsers}
      />

      <div className="row mt-3">
        <div className="col-md-2 text-left">
          <label htmlFor="pageSize">Page Size: </label>
          <select id="pageSize" className="form-control d-inline w-auto ml-2" value={pageSize} onChange={handlePageSizeChange}>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </select>
        </div>
        <div className="col-md-8 text-center">
          <button className="btn btn-secondary mx-2" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button className="btn btn-secondary mx-2" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
        <div className="col-md-2 text-left">
          <span>Total: {totalUsers}</span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
