import React from 'react';

function UsersTable({ users, handleEditUser, handleRemoveUser, selectedUsers, handleSelectUser, handleSelectAllUsers }) {
  const now = new Date();
  const allChecked = users.length > 0 && users.every((user) => selectedUsers.some((sel) => sel.userId === user.userId));
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={handleSelectAllUsers}
              checked={allChecked}
            />
          </th>
          <th scope="col">User ID</th>
          <th scope="col">Capacity</th>
          <th scope="col">Keepalive interval</th>
          <th scope="col">Grace Period</th>
          <th scope="col">Valid until</th>
          <th scope="col" className="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                checked={selectedUsers.some((sel) => sel.userId === user.userId)}
                onChange={() => handleSelectUser(user)}
              />
            </td>
            <td>{user.userId}</td>
            <td>{user.capacity === 0? 'max': user.capacity}</td>
            <td>{user.keepaliveInterval}</td>
            <td>{user.gracePeriod}</td>
            <td>
              {user.optExpireTime ? (
                <p style={{ color: new Date(user.optExpireTime) > now ? 'green' : 'red' }}>
                  {new Date(user.optExpireTime).toLocaleString()}
                </p>
              ): 'N/A'}
            </td>
            <td className="text-end">
              <button className="btn btn-warning btn-sm me-2" onClick={() => handleEditUser(user, index)}>Edit</button>
              <button className="btn btn-danger btn-sm" onClick={(e) => handleRemoveUser(e, user.userId)}>Remove</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default UsersTable;