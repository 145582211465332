import React, { useState, useEffect } from 'react';

function NumericInput({ label, name, placeholder, value, onUpdate, disabled = false, min = 0 }) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    setLocalValue(e.target.value);
  };

  const handleBlur = () => {
    let parsedValue = parseInt(localValue, 10);
    if (isNaN(parsedValue) || parsedValue < min) {
      parsedValue = min;
    }
    setLocalValue(parsedValue);
    onUpdate(name, parsedValue);
  };

  return (
    <div className="col-md-6">
      <label htmlFor={name} className="form-label">{label}</label>
      <input
        type="number"
        className="form-control"
        id={name}
        name={name}
        placeholder={placeholder}
        min={min}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </div>
  );
}

function UserForm({ userData, setUserData, handleSubmit, handleCancel, isEdit }) {
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  };

  const handleNumericUpdate = (name, value) => {
    setUserData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="bg-light p-3 mb-4">
      <h4 className="mb-3 text-left">{isEdit ? 'Edit User' : 'Add New User'}</h4>
      <form onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-md-6">
            <label htmlFor="userId" className="form-label">User ID</label>
            <input
              type="text"
              className="form-control"
              id="userId"
              name="userId"
              placeholder="github-job-1"
              value={userData.userId}
              onChange={handleTextChange}
              disabled={isEdit}
            />
          </div>

          <NumericInput
            label="Capacity"
            name="capacity"
            placeholder="0"
            value={userData.capacity}
            onUpdate={handleNumericUpdate}
            min={0}
          />
          <NumericInput
            label="Keepalive Interval"
            name="keepaliveInterval"
            placeholder="60"
            value={userData.keepaliveInterval}
            onUpdate={handleNumericUpdate}
            min={60}
          />
          <NumericInput
            label="Grace Period"
            name="gracePeriod"
            placeholder="60"
            value={userData.gracePeriod}
            onUpdate={handleNumericUpdate}
            min={60}
          />

          <div className="col-12">
            <button type="button" className="btn btn-secondary me-2" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className="btn btn-success">
              {isEdit ? 'Update' : 'Add New'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserForm;
